<template>
  <div class="viewDashBoard project-route-index">
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="show-project-portal-listing">
            <!-- // Filter Area -->
            <div class="general-filters">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-xl-7 col-lg-7 col-md-4 col-sm-12">
                    <div class="row top-gen-heading">
                      <h1 class="global-style-heading">
                        Brands
                        <a
                          @click.prevent="refreshBrandTable"
                          href=""
                          class="refreshData"
                          ><i class="fas fa-redo-alt"></i
                        ></a>
                      </h1>
                    </div>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-8 col-sm-12">
                    <form>
                      <div class="row">
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                          <div class="row">
                            <div class="form-group">
                              <label for="gender" class="control-label"
                                >&nbsp;</label
                              >
                              <div class="input-group">
                                <input
                                  @keyup="getIBrandData(1)"
                                  v-model="formData.search"
                                  type="text"
                                  class="form-control"
                                  placeholder="Search..."
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-secondary"
                                    type="button"
                                  >
                                    <i class="fa fa-search"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                          <div class="row">
                            <div class="form-actions">
                              <router-link
                                class="btn btn-primary"
                                type="submit"
                                id="save-form"
                                :to="{
                                  name: 'brands.create'
                                }"
                                tag="button"
                              >
                                <i class="fas fa-plus-circle"></i> Add New Brand
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="horizontal-row">
              <hr />
            </div>
            <section class="brands">
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else class="brand-wrapper">
                  <div
                    class="row"
                    v-if="
                      typeof brands_data.data !== 'undefined' &&
                        Object.keys(brands_data.data).length
                    "
                  >
                    <div
                      v-for="(brand, brand_index) in brands_data.data"
                      :key="brand_index"
                      class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    >
                      <div class="wrap-box">
                        <div class="header-top">
                          <dropdown>
                            <template slot="icon"
                              ><i class="fas fa-ellipsis-v"></i
                            ></template>
                            <template slot="body">
                              <ul class="list-group">
                                <li class="list-group-item">
                                  <router-link
                                    :to="{
                                      path: '/brands/edit/' + brand.id
                                    }"
                                    tag="a"
                                  >
                                    Edit
                                  </router-link>
                                </li>
                                <!-- <li class="list-group-item">
                                  <a href="#">Delete</a>
                                </li> -->
                              </ul>
                            </template>
                          </dropdown>
                        </div>
                        <div class="box">
                          <div class="project-details">
                            <p class="project-id">ID: {{ brand.id }}</p>
                            <div class="project-listing">
                              <p class="eclipse-para">
                                {{ brand.name }}
                              </p>
                            </div>
                            <p class="project-status eclipse-para">
                              {{ brand.url }}
                            </p>
                          </div>
                          <div
                            class="footer-box"
                            v-if="brand.brand_accountmanager == 'null'"
                          >
                            <div class="relv">
                              <div class="usr-role">
                                <p>Account Managers:</p>
                              </div>
                              <div class="usr-role-name-glb text-right">
                                <div class="custom-avtr orv-3">
                                  <avatar
                                    :inline="true"
                                    :username="brand.brand_accountmanager.name"
                                  ></avatar>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      No Data Found.
                    </div>
                  </div>
                </div>
              </transition>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import Dropdown from "bp-vuejs-dropdown";
export default {
  components: {
    Avatar,
    Dropdown
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      loader: true,
      data_loader: true,
      formData: {
        search: ""
      },
      brands_data: {}
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.loader = false;
    this.getIBrandData();
  },
  methods: {
    refreshBrandTable() {
      this.getIBrandData();
    },
    async getIBrandData(page = 1, action = null) {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "get-all-brands" +
          "?" +
          "&page=" +
          page +
          "?" +
          "&search=" +
          this.formData.search
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.brands_data = response.data;
          $this.data_loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 95%;
        .input-group {
          input {
            border-radius: 3px !important;
            background: #f9f9f9;
            letter-spacing: 0.28px;
            font-size: 14px;
            padding: 3px 15px;
            color: #364460;
            border: none;
            font-weight: 200;
            height: auto;
          }
          button {
            background: #f9f9f9;
            color: #3b4c54;
            border: none;
            padding: 4px 8px;
          }
        }
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.brands {
        padding-bottom: 0px;
        padding-top: 0;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: auto;
          margin-bottom: 30px;
          .header-top {
            position: absolute;
            right: 26px;
            z-index: 100;
            top: 19px;
            .bp-dropdown__btn {
              border: none;
            }
            .bp-dropdown__body {
              padding: 0;
              background-color: #fff;
              box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
              z-index: 9999;
              border-radius: 4px;
              border: none;
              margin-top: 10px;
              .list-group {
                .list-group-item {
                  border: none;
                  position: relative;
                  display: block;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                  padding: 10px 20px 10px 7px;
                  text-align: left;
                  a {
                    color: #212529;
                  }
                }
              }
            }
          }
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 0;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
